import { steps } from '../../constants/steps';
import { toolCategories } from '../../constants/tools';
import { betaKeys, isBetaEnabled } from '../beta';
import i10n from '../i10n/index';
// ### ActionTypes ###

const actionTypes = {
    ACTION_SUCCESS: "ACTION_SUCCESS",
    ACTION_FAILED: "ACTION_FAILED",
    TOOL_STEP_SET: "TOOL_STEP_SET",
    UPLOAD_WINDOW_TRIGGER_SET: "UPLOAD_WINDOW_TRIGGER_SET",
    TRANSLATION_NOTIFICATION_DISMISS: "TRANSLATION_NOTIFICATION_DISMISS",
    CLEAR: "CLEAR",
    RENAME_OUTPUT_FILE: "RENAME_OUTPUT_FILE",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    CATEGORY_CHANGE: "CATEGORY_CHANGE",
    SEARCH_CHANGE: "SEARCH_CHANGE",
    SET_REDIRECT_URL_ON_LOGIN: "SET_REDIRECT_URL_ON_LOGIN"
};

// ### Reducers ###

const initialState = {
    step: steps.upload,
    triggerUploadWindow: false,
    dismissStatuses: {},
    lang: i10n.currentLang,
    toolCategory: toolCategories.popular,
    searchToolRequest: null,
    redirectToUrlOnLogin: null
}

const toolLayoutReducer = (state = initialState, action) => {
    let newState = state;
    switch (action.type) {
        case actionTypes.ACTION_SUCCESS:
            newState = {
                ...state,
                step: steps.success,
                successMessage: action.payload.message,
                successSubMessage: action.payload.subMessage
            }
            break;

        case actionTypes.ACTION_FAILED:
            newState = {
                ...state,
                step: steps.error,
                errorMessage: action.payload.message,
                errorTitle: action.payload.title,
                isWarning: action.payload.isWarning
            }
            break;

        case actionTypes.TOOL_STEP_SET:
            newState = {
                ...state,
                step: action.payload.step
            }
            break;

        case actionTypes.UPLOAD_WINDOW_TRIGGER_SET:
            newState = {
                ...state,
                triggerUploadWindow: action.payload.triggerUploadWindow
            }
            break;

        case actionTypes.TRANSLATION_NOTIFICATION_DISMISS: {
            let dismissStatuses = { ...state.dismissStatuses };
            dismissStatuses[action.payload.pageId] = true;
            newState = {
                ...state,
                dismissStatuses: dismissStatuses
            }
            break;
        }

        case actionTypes.CLEAR:
            let dismissStatuses = { ...state.dismissStatuses };
            newState = {
                ...initialState,
                dismissStatuses: dismissStatuses,
                lang: state.lang,
                toolCategory: state.toolCategory,
                redirectToUrlOnLogin: state.redirectToUrlOnLogin,
                ...action.payload
            }
            break;
            

        case actionTypes.CHANGE_LANGUAGE:
            newState = {
                ...state,
                lang: action.payload.lang
            }
            break;

        case actionTypes.CATEGORY_CHANGE:
            newState = {
                ...state,
                toolCategory: action.payload.toolCategory
            }
            break;
        case actionTypes.SEARCH_CHANGE:
            newState.searchToolRequest = action.payload.searchToolRequest;
            
        case actionTypes.SET_REDIRECT_URL_ON_LOGIN:
            newState.redirectToUrlOnLogin = action.payload.url;
        default:
            newState = { ...state };
            break;
    }

    return newState;
}

// ### Action Creators ###

export const onActionSuccess = (message, subMessage) => {
    return {
        type: actionTypes.ACTION_SUCCESS,
        payload: {
            message: message,
            subMessage: subMessage
        }
    }
}

export const onChangeLang = (newLanguage) => {
    return {
        type: actionTypes.CHANGE_LANGUAGE,
        payload: {
            lang: newLanguage
        }
    }
}

export const onActionFailed = (message, title = null, isWarning = false) => {
    return {
        type: actionTypes.ACTION_FAILED,
        payload: {
            message: message,
            title: title,
            isWarning: isWarning
        }
    }
}

export const onSetToolAction = (step) => {
    return {
        type: actionTypes.TOOL_STEP_SET,
        payload: {
            step: step
        }
    }
}

export const onSetUploadWindowTrigger = (windowVisible) => {
    return {
        type: actionTypes.UPLOAD_WINDOW_TRIGGER_SET,
        payload: {
            triggerUploadWindow: windowVisible,
        }
    }
}

export const dismissLanguageNotification = (pageId) => {
    return {
        type: actionTypes.TRANSLATION_NOTIFICATION_DISMISS,
        payload: {
            pageId: pageId
        }
    }
}

export const onClear = (payload) => {
    return {
        type: actionTypes.CLEAR,
        payload: payload
    }
}


export const onCategoryChange = (categoryId) => {
    return {
        type: actionTypes.CATEGORY_CHANGE,
        payload: {
            toolCategory: categoryId
        }
    }
}

export const onSearchToolChange = (request) => {
    return {
        type: actionTypes.SEARCH_CHANGE,
        payload: {
            searchToolRequest: request
        }
    }
}

export const setUrlRedirectOnLogin = (url) => {
    return {
        type: actionTypes.SET_REDIRECT_URL_ON_LOGIN,
        payload: {
            url: url
        }
    }
}

export default toolLayoutReducer;