import { PureComponent } from 'react';
import { goTop, ToolStatus } from './tool';
import allTools from '../../constants/tools';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AsyncSVG } from './AsyncSVG';

class OtherToolsComponent extends PureComponent {
    render() {
        var tools = Object.keys(allTools)
            .map(toolKey => allTools[toolKey])
            .filter(tool => tool.status !== ToolStatus.Hidden && tool.status !== ToolStatus.ComingSoon && !this.props.openedToolId.split(',').includes(tool.id))
            .sort(function (a, b) {
                if (a.title()?.toUpperCase() < b.title()?.toUpperCase()) {
                    return -1;
                }
                if (a.title()?.toUpperCase() > b.title()?.toUpperCase()) {
                    return 1;
                }
                return 0;
            });

        return (
            <>
                <ul onClick={goTop} className="footer-list list-3-col">
                    {
                        tools.map(tool =>
                            <li key={tool.id}>
                                {
                                    this.props.showIcon &&
                                    <div className={`tool-item-block-icon ${tool.fillClassName}`}>
                                        <AsyncSVG src={tool.image} />
                                    </div>
                                }
                                <Link to={tool.url()}>
                                    {tool.title()}
                                </Link>
                            </li>)
                    }
                </ul>
            </>);
    }
}



const mapState = ({ tool }) => {
    return {
        language: tool.lang
    };
}
const OtherTools = connect(mapState)(OtherToolsComponent);
export default OtherTools;