import 'bootstrap/dist/css/bootstrap.min.css';
import './style.less';
import './theme.less';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from './store';
import sitemap from './common/sitemap';
import { NotFound } from './pages/not-found';
import { InternalServerError } from './pages/internal-server-error';
import CookiePolicy from './pages/cookie-policy';
import i10n from './common/i10n';
import loadable from '@loadable/component';
import { getLocalizedLink } from './common/components/tool';
import tools from './constants/tools';
//components references
import Home from './pages/home';
import UpdateProfile from './pages/accountPages/update';

const ToolLayout = loadable(() => import('./common/components/layouts/toolLayout'));
const ConfirmEmailContainer = loadable(() => import('./pages/accountPages/confirmEmail'));
const ForgotPasswordContainer = loadable(() => import('./pages/accountPages/forgotPassword'));
const ResetPasswordContainer = loadable(() => import('./pages/accountPages/resetPassword'));
const ExternalLoginContainer = loadable(() => import('./pages/accountPages/externalLogin'));
const LoginContainer = loadable(() => import('./pages/accountPages/login'));
const SignupContainer = loadable(() => import('./pages/accountPages/signup'));
const UpdateProfileContainer = loadable(() => import('./pages/accountPages/update'));
const DocumentViewer = loadable(() => import('./pages/document-viewer'));
const ListPlans = loadable(() => import('./pages/plans'));
const Profile = loadable(() => import('./pages/accountPages/home'));
const ManageSubscription = loadable(() => import('./pages/accountPages/subscription/manage'));
const PurchasePlanSuccess = loadable(() => import('./pages/plans/subscription-added'));
const PurchasePlanError = loadable(() => import('./pages/plans/subscription-failed'));

function getRoute(sitemapKey) {
  let langContraintParameter = `:lang(${
    i10n.getAvailableLanguages()
      .concat({value: 'auto'})
      .map(p => p.value)
      .reduce((langConstraint, currentLang, i) => i == 0 ? currentLang : `${langConstraint}|${currentLang}`, '')})`;
  return [
    sitemapKey === "" ? "" : `/:page(${sitemapKey})`,
    sitemapKey === "" ? `/${langContraintParameter}` :`/${langContraintParameter}/:page(${sitemapKey})`
  ]
}

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router history={history}>
            <Switch>
              <Route exact path={getRoute("")} component={Home} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_LOGIN_ROUTE)} component={LoginContainer} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_EXTERNAL_LOGIN_CALLBACK_ROUTE)} component={ExternalLoginContainer} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_REGISTER_ROUTE)} component={SignupContainer} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_CONFIRM_EMAIL_ROUTE)} component={ConfirmEmailContainer} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_FORGOT_PASSWORD_ROUTE)} component={ForgotPasswordContainer} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_RESET_PASSWORD_ROUTE)} component={ResetPasswordContainer} />
              <Route exact path={getRoute(sitemap.VIEW_HOME_COOKIE_POLICY_ROUTE)} component={CookiePolicy} />
              <Route exact path={getRoute(sitemap.VIEW_DOCUMENT_VIEWER_ROUTE)} component={DocumentViewer} />
              <Route exact path={getRoute(sitemap.VIEW_COMPRESS_PDF_ROUTE)} children={<ToolLayout tool={tools.compress} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDFMRC_ROUTE)} children={<ToolLayout tool={tools.hyperCompress} />} />
              <Route exact path={getRoute(sitemap.VIEW_ORGANIZE_PDF_ROUTE)} children={<ToolLayout tool={tools.organize} />} />
              <Route exact path={getRoute(sitemap.VIEW_ROTATE_PDF_ROUTE)} children={<ToolLayout tool={tools.rotate} />} />
              <Route exact path={getRoute(sitemap.VIEW_MERGE_PDF_ROUTE)} children={<ToolLayout tool={tools.merge} />} />
              <Route exact path={getRoute(sitemap.VIEW_LOCK_PDF_ROUTE)} children={<ToolLayout tool={tools.protect} />} />
              <Route exact path={getRoute(sitemap.VIEW_UNLOCK_PDF_ROUTE)} children={<ToolLayout tool={tools.unlock} />} />
              <Route exact path={getRoute(sitemap.VIEW_SPLIT_PDF_ROUTE)} children={<ToolLayout tool={tools.split} />} />
              <Route exact path={getRoute(sitemap.VIEW_ESIGN_PDF_ROUTE)} children={<ToolLayout tool={tools.esign} />} />
              <Route exact path={getRoute(sitemap.VIEW_CONVERT_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.convert} />} />
              <Route exact path={getRoute(sitemap.VIEW_WORD_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.wordToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_EXCEL_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.excelToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_JPG_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.jpgToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_SVG_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.svgToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_TIFF_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.tiffToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_PPTX_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.pptxToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_PNG_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.pngToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_DXF_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.dxfToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_CONVERT_TO_PDF_A_ROUTE)} children={<ToolLayout tool={tools.convertA} />} />
              <Route exact path={getRoute(sitemap.VIEW_COMBINE_PDF_ROUTE)} children={<ToolLayout tool={tools.combine} />} />
              <Route exact path={getRoute(sitemap.VIEW_OCR_ROUTE)} children={<ToolLayout tool={tools.ocr} />} />
              <Route exact path={getRoute(sitemap.VIEW_OPTIMIZE_PDF_FOR_WEB_ROUTE)} children={<ToolLayout tool={tools.optimizeForWeb} />} />
              <Route exact path={getRoute(sitemap.VIEW_REMOVE_PDF_PAGES_ROUTE)} children={<ToolLayout tool={tools.removePages} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_TIFF_ROUTE)} children={<ToolLayout tool={tools.pdfToTiff} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_JPEG_ROUTE)} children={<ToolLayout tool={tools.pdfToJpg} />} />
              <Route exact path={getRoute(sitemap.VIEW_FLATTEN_PDF_ROUTE)} children={<ToolLayout tool={tools.flattenPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_CROP_PDF_ROUTE)} children={<ToolLayout tool={tools.cropPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_RESIZE_PDF_ROUTE)} children={<ToolLayout tool={tools.resizePdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_REMOVE_DOCUMENT_CONTENT_ROUTE)} children={<ToolLayout tool={tools.removeDocumentContent} />} />
              <Route exact path={getRoute(sitemap.VIEW_REMOVE_INTERACTIVE_CONTENT_ROUTE)} children={<ToolLayout tool={tools.removeInteractiveContent} />} />
              <Route exact path={getRoute(sitemap.VIEW_REMOVE_TEXT_ROUTE)} children={<ToolLayout tool={tools.removeText} />} />
              <Route exact path={getRoute(sitemap.VIEW_SCAN_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.scanningToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_FILL_OUT_PDF_FORMS_ROUTE)} children={<ToolLayout tool={tools.formFieldsFilling} />} />
              <Route exact path={getRoute(sitemap.VIEW_DOCUMENT_BARCODE_ROUTE)} children={<ToolLayout tool={tools.barcodeReco} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_TEXT_ROUTE)} children={<ToolLayout tool={tools.pdfToText} />} />
              <Route exact path={getRoute(sitemap.VIEW_TEXT_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.textToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_MARKUP_PDF_ROUTE)} children={<ToolLayout tool={tools.markupPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_EXTRACT_PDF_PAGES_ROUTE)} children={<ToolLayout tool={tools.extractPdfPages} />} />
              <Route exact path={getRoute(sitemap.VIEW_DICOM_TO_PDF_ROUTE)} children={<ToolLayout tool={tools.dicomToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_REPAIR_ROUTE)} children={<ToolLayout tool={tools.pdfRepair} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_EDIT_ROUTE)} children={<ToolLayout tool={tools.edit} />} />
              <Route exact path={getRoute(sitemap.VIEW_REDACT_PDF_ROUTE)} children={<ToolLayout tool={tools.redactPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_CONVERT_TO_ONE_PAGE_PDF_ROUTE)} children={<ToolLayout tool={tools.convertToOnePagePdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_CLEANUP_PDF_ROUTE)} children={<ToolLayout tool={tools.clean} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_PNG_ROUTE)} children={<ToolLayout tool={tools.pdfToPng} />} />
              <Route exact path={getRoute(sitemap.VIEW_EDIT_PDF_META_ROUTE)} children={<ToolLayout tool={tools.editPdfMeta} />}  />
              <Route exact path={getRoute(sitemap.VIEW_REVERSE_PDF_ROUTE)} children={<ToolLayout tool={tools.reversePdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_PAGING_PDF_ROUTE)} children={<ToolLayout tool={tools.numbers} />} />
              <Route exact path={getRoute(sitemap.VIEW_DOCUMENT_PDFA_VALIDATOR_ROUTE)} children={<ToolLayout tool={tools.validationA} />} />
              <Route exact path={getRoute(sitemap.VIEW_CONVERT_TO_GRAYSCALE)} children={<ToolLayout tool={tools.convertToGrayscale} />} />
              <Route exact path={getRoute(sitemap.VIEW_HTML_TO_PDF)} children={<ToolLayout tool={tools.htmlToPdf} />} />
              <Route exact path={getRoute(sitemap.VIEW_WATERMARK_PDF_ROUTE)} children={<ToolLayout tool={tools.watermark} />} />
              <Route exact path={getRoute(sitemap.VIEW_IMAGE_TO_EXCEL_ROUTE)} children={<ToolLayout tool={tools.imageToExcel} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_WORD_ROUTE)} children={<ToolLayout tool={tools.pdfToWord} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_EXCEL_ROUTE)} children={<ToolLayout tool={tools.pdfToExcel} />} />
              <Route exact path={getRoute(sitemap.VIEW_PDF_TO_POWERPOINT_ROUTE)} children={<ToolLayout tool={tools.pdfToPowerPoint} />} />
              <Route exact path={getRoute(sitemap.VIEW_LIST_PLAN_ROUTE)} component={ListPlans} />
              <Route exact path={getRoute(sitemap.VIEW_PROFILE_ROUTE)} component={Profile} />
              <Route exact path={getRoute(sitemap.VIEW_THANK_YOU_ROUTE)} component={PurchasePlanSuccess} />
              <Route exact path={getRoute(sitemap.VIEW_PURCHASE_ISSUE_ROUTE)} component={PurchasePlanError} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_UPDATE_PROFILE_ROUTE)} component={UpdateProfileContainer} />
              <Route exact path={getRoute(sitemap.VIEW_ACCOUNT_MANAGE_SUBSCRIPTION)} component={ManageSubscription} />
              <Route exact path={getRoute(sitemap.VIEW_NOT_FOUND_ROUTE)} component={NotFound} />
              <Route exact path={getRoute(sitemap.VIEW_INTERNAL_SERVER_ERROR_ROUTE)} component={InternalServerError} />
              <Route render={() => (<Redirect push to={getLocalizedLink(sitemap.VIEW_NOT_FOUND_ROUTE)} />)} />
            </Switch>
        </Router>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
