import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'http://www.slate.com/blogs/normal/2016/10/11/microsoft_word_s_haters_have_it_all_wrong.html?via=gdpr-consent' })}
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1, { type: 'link', href: 'https://www.windowscentral.com/there-are-now-12-billion-office-users-60-million-office-365-commercial-customers' })}
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}
        {renderBlock(i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1, { type: 'link', href: 'https://www.geekwire.com/2018/new-word-processor-wars-fresh-crop-productivity-apps-trying-reinvent-workday/' })}
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1}
    </div>


export const renderSecondColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}
        {renderBlock(i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1, { type: 'link', href: 'https://docs.microsoft.com/en-us/openspecs/dev_center/ms-devcentlp/1c24c7c8-28b0-4ce1-a47d-95fe1ff504bc' })}
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}
    </div>

export const renderThirdColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}<br/>
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}
        {renderBlock(i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1, { type: 'link', href: 'https://www.loc.gov/preservation/digital/formats/fdd/fdd000397.shtml' })}
        {i10n._VIEW_WORDTOPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}
    </div>