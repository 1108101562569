import i10n from "../i10n";
import { countries } from 'country-data-list';

export const detectLocale = () => i10n.currentLang;

export const getLocaleCurrencySymbol = () => {
    let country = navigator.language;
    if (country.length > 2) {
        country= country.slice(-2);
    }
    
    return countries[country.toUpperCase()].currencies.some(cur => cur === 'EUR') ? 'EUR' : 'USD';
}

export const formatCurrency = (value, decimal, currency) => {
    var formatter = new Intl.NumberFormat(i10n.currentLang, { style: 'currency', currency: currency ?? getLocaleCurrencySymbol(), maximumFractionDigits: decimal });

    return formatter.format(value);
}