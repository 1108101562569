import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumn = () =>
    <div>
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Scalable_Vector_Graphics' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1}<br />
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'https://www.w3schools.com/graphics/svg_intro.asp' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1, { type: 'link', href: 'https://www.w3.org/TR/SVG2/access.html' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/raster-vs-vector-graphics-images/' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_10_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_11_PART_1, { type: 'link', href: 'https://www.w3.org/TR/SVG/' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_12_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_13_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Comparison_of_vector_graphics_editors' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_14_PART_1}<br />
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_15_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_16_PART_1, { type: 'link', href: 'https://stackoverflow.com/questions/50829693/what-is-a-modern-browser' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_17_PART_1}
    </div>

export const renderSecondColumn = () => 
    <div>
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Tim_Berners-Lee' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/World_Wide_Web_Consortium#Standards' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}<br />
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}<br />
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}<br />
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_8_PART_1, { type: 'link', href: 'https://www.loc.gov/preservation/digital/formats/fdd/fdd000515.shtml' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_9_PART_1}<br />
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_10_PART_1}
        {renderBlock(i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_11_PART_1, { type: 'link', href: 'https://www.loc.gov/preservation/resources/rfs/' })}
        {i10n._VIEW_SVGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_12_PART_1}
    </div>