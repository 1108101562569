import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumnWithAddedLink = () =>
<div>
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1} 
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1} 
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1, { type: 'AvePDFLink', href: tools.ocr.url })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1} 
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1, { type: 'AvePDFLink', href: tools.barcodeReco.url })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1} 
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1} 
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1, { type: 'AvePDFLink', href: tools.hyperCompress.url })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_10_PART_1} 
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_11_PART_1, { type: 'AvePDFLink', href: tools.convertA.url })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_1_DESC_12_PART_1}
    </div>

export const renderSecondColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/image-enhancement-median-filtering-2/' })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}
    </div>

    export const renderThirdColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/deskew-autodeskew-definition/' })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/color-adjustments-brightness-contrast-and-gamma-2/' })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_7_PART_1}
        <br />
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_8_PART_1}
        {renderBlock(i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_9_PART_1, { type: 'AvePDFLink', href: tools.cropPdf.url })}
        {i10n._VIEW_CLEANUPPDF_DIDYOUKNOW_COLUMN_3_DESC_10_PART_1}
    </div>