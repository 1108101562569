import { betaKeys, isBetaEnabled } from './beta'

window.dataLayer = window.dataLayer || [];

function gtag() { window.dataLayer.push(arguments); }

export const initGALocationListener = (history) => {
    window.dataLayer = window.dataLayer || [];
    
    gtag('js', new Date());
    gtag('config', 'UA-112327012-2');

    history.listen((location) => {
        if (typeof gtag !== "undefined" && gtag) {
            gtag('config', 'UA-112327012-2', {
                'page_title': '',
                'page_path': location.pathname
            });
        }
    });
};

export const sendLocalDownloadFileEvent = () => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'local_download', {
            'event_category': 'download'});
    }
};

export const sendGoogleDriveDownloadFileEvent = () => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'google_drive_download', {
            'event_category': 'download'
        });
    }
};

export const sendDropBoxDownloadFileEvent = () => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'drop_box_download', {
            'event_category': 'download'
        });
    }
};

export const sendOneDriveDownloadFileEvent = () => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'one_drive_download', {
            'event_category': 'download'
        });
    }
};

export const sendCategorySelectedEvent = (category) => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', category + "_category_selected", {
            'event_category': 'tool_category_selected'
        });
    }
};

export const sendSearchBarSelectedEvent = () => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'tool_search_bar_selected', {
            'event_category': 'tool_search_bar_selected'
        });
    }
};

export const sendTextSearchToolNotFoundEvent = (searchWords) => {
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'text_search_tool_not_found', {
            'event_category': 'text_search_tool_not_found',
            'event_label': searchWords
        });
    }
};

export const sendEmbededWidgetOrigin = (embeddedLocation) => {
    if (!isBetaEnabled(betaKeys.BETA_OHO_AVE1137_GTAG_EMBEDDED_ORIGIN)) {
        return;
    }
    if (typeof gtag !== "undefined" && gtag) {
        gtag('event', 'embedded_widget_origin', {
            'event_category': 'embedded_widget_origin',
            'url': `${embeddedLocation.origin}${embeddedLocation.pathname}`,
        });
    }
};