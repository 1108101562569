import { Component } from 'react';
import { connect } from 'react-redux';
import clear from './clear';
import moment from 'moment';
import { Alert } from 'antd';
import i10n from '../i10n';
import { UserHelper } from '../helpers/userHelper';
import { formatDuration, Timer } from '../helpers/utilities';
import { Link } from 'react-router-dom';
import { getLocalizedLink } from './tool';
import sitemap from '../sitemap';

class UsageBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            creditLeft: null,
            limitResetAt: null,
            msLeft: null
        }
    }

    componentDidMount = () => {
        this.refreshBannerState();
        UserHelper.RegisterOnUsageChange(() => this.refreshBannerState());
    }

    componentWillUnmount () {
        clearInterval(this.timerCreditReset);
        this.timerCreditReset = null;
    }

    shouldComponentUpdate(nProps) {
        if (this.props.files?.filter(file => file.downloaded).length !== nProps.files?.filter(file => file.downloaded).length){
            //We have to delay the Usage retrieval, in order to let server taking in account the new limitation
            //And we don't have any synchronization mechanism because of the way the file are being downloaded from the browser (window.location = DOWNLOAD_URI) 
            setTimeout(() => UserHelper.RefreshUsage().then(() => delete window.hideBanner), 500)
        }
        return true;
    }

    timerCreditReset = null;

    shouldSetTimer = () => {
        if (this.timerCreditReset === null && this.state.creditLeft === 0 && this.state.limitResetAt !== null) {
            const durationMs = moment.duration(moment(this.state.limitResetAt).diff(moment.utc()));
            this.timerCreditReset = Timer(
                durationMs, 
                500, 
                progress => this.setState({ msLeft: progress.ms - durationMs }),
                () => UserHelper.RefreshUsage().then(() => this.refreshBannerState()));
        }
    }

    refreshBannerState() {
        const usage = UserHelper.GetUsage();
        this.setState({
            creditLeft: usage?.creditsLeft ?? UserHelper.GetCurrentPlan().MaxDailyActions,
            limitResetAt: usage?.nextCreditReload
        }, this.shouldSetTimer);
    }

    getBannerLabel = () => {
        if (this.state.creditLeft === 0 && this.state.limitResetAt !== null) {
            const now = moment.utc(), resetAt = moment.utc(this.state.limitResetAt);
            if (resetAt.isValid() && now.isBefore(resetAt)) {
                const duration = moment.duration(resetAt.diff(now));
                return <>{i10n._VIEW_LAYOUT_USAGE_LIMIT_BANNER_RESET_IN_1.replace("#xxx", formatDuration(duration))} <Link to={getLocalizedLink(sitemap.VIEW_LIST_PLAN_ROUTE)}> AvePDF {UserHelper.GetPremiumPlan().Name}</Link> {i10n._VIEW_LAYOUT_USAGE_LIMIT_BANNER_RESET_IN_2}</>;
            } else if (resetAt.isValid() && now.isSameOrAfter(resetAt)) {
                UserHelper.RefreshUsage();
                this.setState({
                    creditLeft: 1
                });
            }
        }
        return <>{(this.state.creditLeft === 1 ? i10n._VIEW_LAYOUT_USAGE_LIMIT_BANNER_ACTIONS_LEFT_1_SIGULAR : i10n._VIEW_LAYOUT_USAGE_LIMIT_BANNER_ACTIONS_LEFT_1_PLURAL)
            .replace("#xxx", this.state.creditLeft ?? UserHelper.GetCurrentPlan().MaxDailyActions)} <Link to={getLocalizedLink(sitemap.VIEW_LIST_PLAN_ROUTE)}> 
            AvePDF {UserHelper.GetPremiumPlan().Name}</Link> {i10n._VIEW_LAYOUT_USAGE_LIMIT_BANNER_ACTIONS_LEFT_2}</>;
    }

    hideBanner = () => {
        window.hideBanner = true;
    }

    render() {
        if (window.hideBanner) {
            return <></>
        }
        
        return (            
                <Alert message={this.getBannerLabel()} banner closable onClose={this.hideBanner} />
            );
    }
}

const mapState = ({ tool, files }, ownProps) => {
    return {
        files: files.processedContext
    };
}

const mapDispatch = (dispatch) => {
    return {

    }
}
const UsageBannerContainer = clear(connect(mapState, mapDispatch)(UsageBanner));
export default UsageBannerContainer;