import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { onSearchToolChange } from '../../common/reducers/toolLayoutReducer';
import { AsyncSVG } from '../../common/components/AsyncSVG';
import { sendSearchBarSelectedEvent } from '../../common/trackers';
import i10n from '../../common/i10n';
import { Button, Input } from 'antd';
import { Link } from 'react-router-dom';

class SearchTool extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchSentence: this.props.searchToolRequest
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.searchToolRequest !== null && this.props.searchToolRequest === null) {
            this.setState({
                searchSentence: null
            });
        }
    }

    onSearchChange = (e) => {
        var value = e.target.value;

        if (value.match(/^\s+$|^$/gi)) {
            value = null;
        }

        this.setState({
            searchSentence: value
        }, this.onSearchChanged);
    }

    onSearchChanged() {
        this.props.onSearchToolChange(this.state.searchSentence);
    }

    render() {
        let searchButton =
            <Button id="search-tool-btn" aria-label="Search AvePDF tools" className="header-search-button" disabled={this.props.passiveMode === false || this.state.searchSentence === null}>
                <span className="search-icon"><AsyncSVG src="/images/search-tools.svg" /></span>
            </Button>;
        return (
            <>
                
                    <div className='search-wrapper'>

                        {/* This is chrome specific, it avoid autofill mecanism to fullfill search tool input with credential */}
                        <input style={{ display: 'none' }} type="text" name="fakeusernameremembered" />
                        <input style={{ display: 'none' }} type="password" name="fakepasswordremembered" />

                        <Input
                            value={this.state.searchSentence === null ? "" : this.state.searchSentence}
                            onChange={this.onSearchChange}
                            onClick={sendSearchBarSelectedEvent}
                            onKeyDown={e => e.key === "Enter" && document.getElementById("search-tool-btn").click()}
                            type="text"
                            className="search-tools"
                            placeholder={i10n._VIEW_HOME_TOOL_SEARCH_PLACEHOLDER}
                            autoComplete="off"
                        // addonBefore={
                        //     <>
                        //         {
                        //             (!this.props.passiveMode ||  (this.props.passiveMode && this.state.searchSentence == null)) &&
                        //             <>
                        //                 {searchButton}
                        //             </>
                        //             ||
                        //             <Link to={""}>
                        //                 {searchButton}
                        //             </Link>
                        //         }
                        //     </>
                        // }
                        />
                    </div>
                
            </>
        )
    }
}

const mapState = ({ tool }) => {
    return {
        step: tool.step,
        searchToolRequest: tool.searchToolRequest
    };
}

const mapDispatch = (dispatch) => {
    return {
        onSearchToolChange(request) {
            dispatch(onSearchToolChange(request));
        }
    }
}
const SearchToolContainer = connect(mapState, mapDispatch)(SearchTool);
export default SearchToolContainer;