import React, { PureComponent } from 'react';
import { Layout, Modal, Button, notification } from 'antd';
import { getLocalizedLink } from '../tool';
import { Link, withRouter } from 'react-router-dom';
import i10n from '../../i10n';
import { connect } from 'react-redux';
import { dismissLanguageNotification } from '../../reducers/toolLayoutReducer';
import { onRemoveProcessedContext, onContextDownload } from '../../reducers/uploadFileReducer';
import ThemeSwitch from '../controls/themeSwitch';
import sitemap from '../../../common/sitemap';
import { pageId as homePageId } from '../../../pages/home/index';
import { AsyncSVG } from '../AsyncSVG';
import FileService from '../../services/fileService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faDownload, faSquareArrowUpRight} from '@fortawesome/free-solid-svg-icons';
import OtherTools from '../otherTools';
import { UserHelper } from '../../helpers/userHelper';
import config from '../../../config';
import UsageBanner from '../UsageBanner';
import MegaMenu from '../megaMenu';
import {ReactComponent as AiStar } from '../../../assets/images/avelyn-ai-star.svg';


const { Content } = Layout;

export const ToolStatus = {
    None: 0,
    New: 1,
    ComingSoon: 2
}

export class CommonLayoutView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            anonymous: !UserHelper.IsLoggedIn(),
            userInfo: null,
            showHamburgerMenu: false,
            termsModalVisible: false,
            hamburgerMenuOpenItem: null,
            theme: null,
            showRemoveFileIcon: false,
            showMegaMenu: false
        };
    }

    componentDidMount() {
        const userInfo = UserHelper.GetUser();

        if (userInfo !== null) {
            UserHelper.EnsureUserIsLogged().then(() => {
                this.setState({
                    anonymous: false,
                    userInfo: userInfo,
                })
            }).catch(() => {
                UserHelper.LogOff(true);
            })
        }

        UserHelper.RegisterOnUserChange(() => this.userChanged())

        let query = new URLSearchParams(this.props.location.search);
        if (query.get("showPolicy") && new Boolean(query.get("showPolicy"))) {
            this.setState({ termsModalVisible: true })
        }
    }

    componentWillUnmount = () => {
        UserHelper.UnRegisterOnUserChange();
    }

    selectLanguage = (lang) => {
        if (i10n.currentLang === lang) {
            return;
        }
        postMessage({ type: 'change-lang', payload: lang });
    }

    onDismiss = () => {
        if (this.props.pageId) {
            this.props.dismiss(this.props.pageId);
        }
    }

    userChanged = () => {
        this.setState({
            anonymous: !UserHelper.IsLoggedIn(),
            userInfo: UserHelper.GetUser()
        });
    }

    toggleHamburgerMenu = () => {
        this.setState({
            showHamburgerMenu: !this.state.showHamburgerMenu,
            hamburgerMenuOpenItem: null,
            showMegaMenu: false
        });
    }

    toggleMegaMenu = () => {
        this.setState({
            //showHamburgerMenu: false,
            hamburgerMenuOpenItem: null,
            showMegaMenu: !this.state.showMegaMenu
        });
    }

    getLanguageLabel = () => {
        return i10n.getAvailableLanguages().find((el) => el.value == i10n.currentLang);
    }

    closeMenu = (event) => {
        if (this.state.showHamburgerMenu) {
            var elm = document.getElementById('avepdfnavbar');
            if (!elm.contains(event.target)) {
                this.setState({
                    showHamburgerMenu: false,
                    hamburgerMenuOpenItem: null
                });
            }
        } else if (this.state.showMegaMenu) {
            var elm = document.getElementById('megamenu');
            if (!elm.contains(event.target)) {
                this.setState({
                    showMegaMenu: false,
                    hamburgerMenuOpenItem: null
                });
            }
        }
    }

    downloadFile = context => {
        var downloadElement = window.document.createElement('a');
        downloadElement.download = context.outputFileName;
        downloadElement.href = `/${i10n.currentLang}/${sitemap.FILES_CONTROLLER_ROUTE}/${sitemap.DOWNLOAD_CLIENT_ROUTE}/${context.id}?filename=${encodeURIComponent(context.outputFileName)}`;
        downloadElement.click();
        this.props.setContextDownloadFlag(context.id);
    }

    removeFile = context => {
        FileService.removeFile(context.id, true, true, true).then(() => {
            this.props.removeFileContext(context.id);
            notification.open({
                message: i10n._VIEW_LAYOUT_NOTIFICATION_REMOVED_FILE,
                duration: 3
            });
        });
    }

    render() {
        var previousFileAvailable = this.props.previousFiles.length > 0;
        let previousContext = null;
        if (previousFileAvailable) {
            previousContext = this.props.previousFiles[0];
        }

        const currentYear = new Date().getFullYear();

        if (!!document.documentMode) { //IE browser
            return (
                <div className="ie-user-screen">
                    <div className="ie-error">
                        <p>{i10n._VIEW_COMMONLAYOUT_IE_ERROR}</p>
                        <a href="https://www.microsoft.com/edge" rel="noopener noreferrer" target="_blank">{i10n._VIEW_COMMONLAYOUT_IE_ERROR_DOWNLOAD_CHROME}</a>
                    </div>
                </div>
            );
        }
        var isLightTheme = document.documentElement.getAttribute("data-theme") !== "dark";
        return (
            <div className="h-100">
                <div className="width_100_per height_100_per">
                    <div className="flexbox-parent" onClick={this.closeMenu}>

                        {/* <CompanyEvent /> */}

                        <div className="ave-header">
                            <div className="container header" id="header">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <nav className="ave-navbar navbar navbar-expand-lg">

                                            <div className="logo-wrapper">
                                                <Link aria-label="AvePDF Logo" to={getLocalizedLink('')}>
                                                    <AsyncSVG
                                                        src="/images/logo.svg"
                                                        id="ave-pdf-logo-container"
                                                        className="logo img-fluid"
                                                    />
                                                </Link>
                                            </div>

                                            <div className="file-header-wrapper" onMouseEnter={() => this.setState({ showRemoveFileIcon: true })} onMouseLeave={() => this.setState({ showRemoveFileIcon: false })}>
                                                {
                                                    previousFileAvailable &&
                                                    <>
                                                        {
                                                            this.state.showRemoveFileIcon &&
                                                            <button className="remove-file-header" onClick={() => this.removeFile(previousContext)}>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </button>
                                                        }
                                                        <button className="download-file-header" aria-label="Available files" onClick={() => this.downloadFile(previousContext)}>
                                                            {
                                                                !previousContext.downloaded && !this.state.showRemoveFileIcon &&
                                                                <span className="notification-dot"></span>
                                                            }
                                                            <p>
                                                                <span className="file-name">{previousContext.outputFileName}</span>
                                                                <FontAwesomeIcon className="ml-2 mr-0" icon={faDownload} />
                                                            </p>
                                                        </button>
                                                    </>
                                                }
                                            </div>

                                            <button aria-label="Main Navigation" className={`navbar-toggler hamburger-menu-button ml-auto mr-0 ${this.state.showHamburgerMenu ? 'active' : ''}`} onClick={this.toggleHamburgerMenu}>
                                                <span>
                                                </span>
                                            </button>

                                            <div className={`collapse navbar-collapse ${this.state.showHamburgerMenu ? 'show' : ""}`} id="avepdfnavbar">
                                                <ul className="navbar-nav ml-auto">
                                                    <li className="nav-item dropdown megamenu">
                                                        <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  onClick={this.toggleMegaMenu}>
                                                            {i10n._VIEW_LAYOUT_MENU_TOOL}
                                                        </a>
                                                        <div className={`dropdown-menu ${this.state.showMegaMenu ? "show" : "" }`} aria-labelledby="navbarDropdown">
                                                            <div className="megamenu-tools" id="megamenu">
                                                                <MegaMenu showIcon={true} currentPageId={this.props.pageId} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="nav-link" to={getLocalizedLink(sitemap.VIEW_LIST_PLAN_ROUTE)}>
                                                            {i10n._MENU_PRICING_TITLE}
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="https://blog.avepdf.com/" target="_blank" rel="noopener">
                                                            {i10n._MENU_BLOG_TITLE}
                                                        </a>
                                                    </li>

                                                    {
                                                        this.state.anonymous &&
                                                        <>
                                                            <li className="nav-item">
                                                                <Link to={getLocalizedLink(sitemap.VIEW_ACCOUNT_LOGIN_ROUTE)} className="nav-link">
                                                                    {i10n._VIEW_ACCOUNT_SIGNIN_BUTTON}
                                                                </Link>
                                                            </li>
                                                        </>
                                                        ||
                                                        <>
                                                            <li className="nav-item">
                                                                <Link to={getLocalizedLink(sitemap.VIEW_PROFILE_ROUTE)} className="nav-link">
                                                                    {i10n._MENU_MANAGE_TITLE}
                                                                </Link>
                                                            </li>
                                                        </>
                                                    }
                                                    
                                                    <li id="avelyn-btn-header" className="nav-item">
                                                        <a href={sitemap.AVELYN_HOME} target="_blank" className="btn">
                                                            <AiStar className={"svg-inline--fa fa-square-arrow-up-right fa-w-14 mr-1"} />
                                                            {i10n._VIEW_LAYOUT_AVELYN_AI_VIEWER}
                                                            <FontAwesomeIcon className="ml-2" icon={faSquareArrowUpRight} />
                                                        </a>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="premium-banner">
                            {
                                !(config.isSnapshootingCrawlers || config.isSnapshootingUsers) && !UserHelper.IsUsingPaidPlan() &&
                                <UsageBanner />
                            }
                        </div>

                        <div className={`flexbox-item flexbox-item-grow`}>

                            <div className="container">
                                <Content className='content'>
                                    {this.props.children}
                                </Content>
                            </div>

                            {
                                this.props.displayAdsGdp === true && this.state.anonymous &&
                                <div className="new-sdk-offer-wrapper">
                                    <div className="sdk-offer-container">
                                        <div className="container">
                                            <div className="row align-items-center py-5 my-3">
                                                <div className="col-lg-6 p-md-3 p-lg-5 p-3">
                                                    <h3 className="title-fancy title-small">
                                                        <span className="title-fancy-icon"><AsyncSVG className="link-arrow" src='/images/title-icon.svg' /></span>
                                                        {i10n._VIEW_LAYOUT_GDP_ADS_TITLE}
                                                    </h3>
                                                    <p className="lead lead-small normal my-4">{i10n._VIEW_LAYOUT_GDP_ADS_SUBTITLE}</p>
                                                    <ul className="sdk-features-list lead lead-xsmall normal">
                                                        <li>{i10n._VIEW_LAYOUT_GDP_ADS_TYPE_PDF}, {i10n._VIEW_LAYOUT_GDP_ADS_TYPE_CAD} {"&"} {i10n._VIEW_LAYOUT_GDP_ADS_TYPE_OFFICE}</li>
                                                        <li>{i10n._VIEW_LAYOUT_GDP_ADS_TYPE_OCR}</li>
                                                        <li>{i10n._VIEW_LAYOUT_GDP_ADS_TYPE_BARCODES}</li>
                                                        <li>{i10n._VIEW_LAYOUT_GDP_ADS_TYPE_ACQUISITION}</li>
                                                        <li>{i10n._VIEW_LAYOUT_GDP_ADS_TYPE_VIEWER}</li>
                                                        <li>{i10n._VIEW_LAYOUT_GDP_ADS_TYPE_CONVERTER}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-6">

                                                            <div className="new-sdk-offer-card card">
                                                                <div className="card-body">
                                                                    <div className="sdk-offer-card-logo gdp-logo">
                                                                        <AsyncSVG
                                                                            src="/images/gdpicture-logo.svg"
                                                                            id="gdpicture-logo-container"
                                                                            className="img-fluid"
                                                                        />
                                                                    </div>
                                                                    <p className="sdk-offer-card-description">{i10n._VIEW_LAYOUT_GDP_ADS_TARGET_DESKTOP}</p>
                                                                    <a className="ant-btn ant-btn-primary btn-block" aria-label="GdPicture.NET Link" href="https://www.gdpicture.com/" target="_blank" rel="noopener">{i10n._VIEW_LAYOUT_GDP_ADS_LEARN_MORE} →</a>
                                                                </div>
                                                            </div>

                                                            <div className="new-sdk-offer-card card">
                                                                <div className="card-body">
                                                                    <div className="sdk-offer-card-logo">
                                                                        <img alt="DocuVieware Logo" className="img-fluid" width="200" height="58" src='/images/docuvieware-logo.svg' />

                                                                    </div>
                                                                    <p className="sdk-offer-card-description">{i10n._VIEW_LAYOUT_GDP_ADS_TARGET_WEB}</p>
                                                                    <a className="ant-btn ant-btn-primary btn-block" aria-label="DocuVieware Link" href="https://www.docuvieware.com/" target="_blank" rel="noopener">{i10n._VIEW_LAYOUT_GDP_ADS_LEARN_MORE} →</a>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">

                                                            <div className="new-sdk-offer-card card">
                                                                <div className="card-body">
                                                                    <AsyncSVG className="sdk-offer-card-logo pp-logo" src='/images/passportpdf-logo.svg' />
                                                                    <p className="sdk-offer-card-description">{i10n._VIEW_LAYOUT_GDP_ADS_TARGET_CLOUD}</p>
                                                                    <a className="ant-btn ant-btn-primary btn-block" aria-label="PassportPDF Link" href="https://passportpdf.com/" target="_blank" rel="noopener">{i10n._VIEW_LAYOUT_GDP_ADS_LEARN_MORE} →</a>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>


                        <div className={`ave-footer ${this.state.anonymous && this.props.pageId === homePageId ? "sdk-offer-footer" : ""}`}>
                            <div className="ave-footer-top new-footer-top">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-6">
                                            <h4 className="footer-title">{i10n._VIEW_TOOL_LAYOUT_PDF_TOOLS_TITLE}</h4>
                                            <OtherTools openedToolId={this.props.pageId} />
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <h4 className="footer-title">{i10n._MENU_LANGUAGES}</h4>
                                            <ul className="footer-list list-2-col">
                                                {
                                                    i10n.getAvailableLanguages().map(lang =>
                                                        <li key={lang.value} className={`${i10n.currentLang === lang.value ? "active-lang" : ""}`}>
                                                            <Link
                                                                onClick={e => { e.preventDefault(); this.selectLanguage(lang.value) }}
                                                                to={getLocalizedLink(this.props.match.params.page, lang.value)}
                                                            >
                                                                {lang.text}
                                                            </Link>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-xl-3">
                                            <h4 className="footer-title">{i10n._MENU_DEVELOPPER}</h4>
                                            <ul className="footer-list">
                                                <li>
                                                    <a onClick={this.toggleHamburgerMenu} href="https://gdpicture.com/" target="_blank" rel="noopener">
                                                        <span className="dev-icon">
                                                            <img alt="GdPicture.NET Icon" width="20" height="20" src='/images/gdpicture-icon.svg' />
                                                        </span>
                                                        GdPicture.NET
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={this.toggleHamburgerMenu} href="https://docuvieware.com/" target="_blank" rel="noopener">
                                                        <span className="dev-icon docuvieware-icon ">
                                                            <img alt="DocuVieware Icon" width="20" height="20" src='/images/docuvieware-icon.svg' />
                                                        </span>
                                                        DocuVieware
                                                    </a>
                                                </li>
                                            </ul>
                                            <h4 className="footer-title">{i10n._MENU_LEGAL}</h4>
                                            <ul className="footer-list">
                                                <li><a href="https://www.iubenda.com/privacy-policy/32291156" rel="noopener" className="footer-policy-link iubenda-nostyle iubenda-embed" title={i10n._FOOTER_PRIVACY_BUTTON}>{i10n._FOOTER_PRIVACY_BUTTON}</a></li>
                                                <li><a href="#" className="footer-policy-link" title={i10n._FOOTER_TERMS_BUTTON} onClick={() => { this.setState({ termsModalVisible: true }) }}>{i10n._FOOTER_TERMS_BUTTON}</a></li>
                                            </ul>
                                            <h4 className="footer-title">{i10n._LAYOUT_CONTACT_US}</h4>
                                            <ul className="footer-list">
                                                <li><a href="mailto:contact@avepdf.com" target="_blank" rel="noopener">contact@avepdf.com</a></li>
                                            </ul>
                                            <div className="theme-switch-wrapper"><ThemeSwitch onThemeChange={theme => this.setState({ theme: theme })} /> {this.state.theme === "light" ? i10n._MENU_THEME_LIGHT : i10n._MENU_THEME_DARK}</div>

                                            <p>{i10n._LABEL_COPYRIGHT.split("ORPALIS")[0].trim() + `${currentYear} `}
                                                <a className="footer-policy-link" href="https://www.orpalis.com/" target="_blank" rel="noopener"><strong>Orpalis Imaging SAS</strong></a>.<br />
                                                {i10n._LABEL_COPYRIGHT.split("ORPALIS")[1].substring(2)}
                                                {"."}</p>

                                            <a aria-label="Powered By GdPicture.NET" href="https://gdpicture.com" target="_blank" rel="noopener">
                                                <AsyncSVG className="powered-by-container" src="/images/avepdf-powered-by-inline.svg" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal
                                wrapClassName={`${this.props.fillClassName}`}
                                title={i10n._FOOTER_TERMS_BUTTON}
                                visible={this.state.termsModalVisible}
                                onCancel={() => { this.setState({ termsModalVisible: false }) }}
                                width="80%"
                                footer={[
                                    <Button key="back" onClick={() => { this.setState({ termsModalVisible: false }) }}>
                                        {i10n._VIEW_HOME_LEGAL_CLOSE}
                                    </Button>
                                ]}>
                                <div className="row" id="scroll-terme-of-service" style={{ margin: "30px 30px 0px 30px" }}>
                                    <div className="col-md-12">
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE15}</p>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE16}</p>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE17}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE13}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE18}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE14}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE19}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE15}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE20}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE16}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE21}</p>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE22}</p>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE23}</p>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE24}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE17}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE25}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE18}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE26}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE19}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE27}</p>

                                        <div className="white-space space-small"></div>

                                        <h3 className="title-exsmall normal">{i10n._VIEW_HOME_LEGAL_TITLE20}</h3>
                                        <p>{i10n._VIEW_HOME_LEGAL_LINE28}</p>

                                        <p>{i10n._VIEW_HOME_LEGAL_LINE29}</p>

                                        <p>{i10n._VIEW_HOME_LEGAL_LINE30}</p>

                                        <p>{i10n._VIEW_HOME_LEGAL_LINE31}</p>

                                        <div className="white-space space-small"></div>

                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapState = ({ tool, files }, ownProps) => {
    return {
        dismissStatus: tool.dismissStatuses[ownProps.pageId],
        lang: tool.lang,
        previousFiles: files.processedContext
    };
}

const mapDispatch = (dispatch) => {
    return {
        dismiss(pageId) {
            dispatch(dismissLanguageNotification(pageId));
        },
        removeFileContext(id) {
            dispatch(onRemoveProcessedContext(id));
        },
        setContextDownloadFlag(id) {
            dispatch(onContextDownload(id));
        }
    }
}
export const CommonLayout = withRouter(connect(mapState, mapDispatch)(CommonLayoutView));
