import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import { detectLocale } from '../../../common/helpers/detectLocale';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';



export const renderFirstColumn = () =>
    <div>{i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/briefly-about-jpeg/' })}
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}<br/>
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}<br/>
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}<br/>
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1}
    {renderBlock(i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Lossless_JPEG' })}
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/which-bitmap-format-to-choose/' })}
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}<br/>
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}<br/>
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1, { type: 'AvePDFLink', href: tools.convertA.url })}<br/>
    {i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}
        {renderBlock(i10n._VIEW_JPGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_8_PART_1, { type: 'AvePDFLink', href: tools.dicomToPdf.url })}</div>
