import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import { detectLocale } from '../../../common/helpers/detectLocale';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumn = () =>
    <div>{i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Forethought,_Inc.'})}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1, { type: 'link', href: 'https://www.free-power-point-templates.com/articles/history-of-powerpoint-the-amazing-facts-you-did-not-know/'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1, { type: 'link', href: 'https://www.britannica.com/technology/Microsoft-PowerPoint'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Microsoft_PowerPoint'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_1_DESC_10_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://slate.com/technology/2019/08/powerpoint-world-champion-alabama-teenager.html'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1, { type: 'link', href: 'https://www.inc.com/geoffrey-james/harvard-just-discovered-that-powerpoint-is-worse-than-useless.html'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Cognitive_load'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_8_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_9_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_10_PART_1, { type: 'link', href: 'https://theweek.com/articles/673091/general-mattis-save-military-ban-powerpoint'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_2_DESC_11_PART_1}</div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}
        {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1, { type: 'AvePDFLink', href: tools.wordToPdf.url })}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}
    {renderBlock(i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_7_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Office_Open_XML'})}
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_8_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_9_PART_1}<br/>
    {i10n._VIEW_PPTXOPDF_DIDYOUKNOW_COLUMN_3_DESC_10_PART_1}</div>
