import localStorageKeys from "../../constants/localStorageKeys";
import AuthenticationService from "../services/authService";
import FileService from '../services/fileService'
import moment from 'moment';
import axios from "axios";
import { GetUrl } from "../services/utilities";
import sitemap from "../sitemap";
import { saveItem } from "./localeStorage";

class UserHelperClass {
    GetUser() {
        return navigator.cookieEnabled ? JSON.parse(localStorage.getItem(localStorageKeys.USER)) : null;
    }

    IsLoggedIn() {
        return this.GetUser() !== null;
    }

    EnsureUserIsLogged() {
        return AuthenticationService.isLogged().then(resp => {
            if (resp.data.logged === false){
                throw "User is not logged";
            }
        })
    }

    StoreUser = user => {
        localStorage.setItem(localStorageKeys.USER, JSON.stringify(user));
        this._userChangedFn();
    }

    LogOff = (skipLogoffRequest) => {
        return AuthenticationService.logout(skipLogoffRequest).finally(this._userChangedFn);
    }


    _userChangedFn = null;

    RegisterOnUserChange = fn => this._userChangedFn = fn;
    UnRegisterOnUserChange = () => this._userChangedFn = null;

    GetCurrentPlan() {
        if (UserHelper.IsLoggedIn()){
            const user = UserHelper.GetUser();
            const paidPlan = window.AvePDFAppState.Plans.find(plan => plan.Id !== null && plan.Id == user.planId);
            if (paidPlan) {
                return paidPlan;
            }
        }
        return window.AvePDFAppState.Plans[0]; //first is free plan
    }

    GetPremiumPlan = () => window.AvePDFAppState.Plans[window.AvePDFAppState.Plans.length - 1];

    IsUsingPaidPlan = () => this.GetCurrentPlan().Id !== null;

    RefreshLocaleUserInfo = () => AuthenticationService.getUserInfo().then(response => {
        if (response.status === 200 && response.data !== null){
            this.StoreUser(response.data);
        }
    });

    GetPlansPrices = () => {
        return axios.get(GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_GET_PLANS_PRICE ])).then(resp => resp.data.plans);
    }

    //#region Usage Manager
    _fn = null;
    InitUsage = () => {
        //At application start, if browser has already a usage stored in local memory, we refresh it 
        if (localStorage.getItem(localStorageKeys.USAGE) !== null) {
            this.RefreshUsage();
        }
    }
    RefreshUsage = async () => {
        if (this.IsUsingPaidPlan()){
            return;
        }
        const response = await FileService.getUsage();
        localStorage.setItem(localStorageKeys.USAGE, JSON.stringify(response.data));
        if (this._fn) {
            this._fn();
        }
    }

    RegisterOnUsageChange = fn => this._fn = fn;
    UnRegisterOnUsageChange = () => this._fn = null;

    GetUsage = () => {
        // We're not checking usage limit on widget mode
        if (window.embedded)
            return null;

        const usage = navigator.cookieEnabled ? JSON.parse(localStorage.getItem(localStorageKeys.USAGE) ?? null) : null;
        
        // if delay is passed to get new credit, removing usage obj
        if (usage && moment.utc(usage.NextCreditReload).isAfter(moment.utc())) {
            localStorage.removeItem(localStorageKeys.USAGE);
            return null;
        }

        return usage;
    }
    //#endregion

    //#region persisted data

    SaveToolSettingLang(lang){
        saveItem(localStorageKeys.OCR_LANG, lang);
    }

    GetToolSettingLang() {
        return localStorage?.getItem(localStorageKeys.OCR_LANG) ?? null;
    }
    //#endregion
}

export const UserHelper = new UserHelperClass();