import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumnWithAddedLink = () =>
    <div>{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}{renderBlock(i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Autodesk' })}{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}<br/>{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}</div>


export const renderSecondColumnWithAddedLink = () =>
    <div>{renderBlock(i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/raster-vs-vector-graphics-images/' })}{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}<br/>{renderBlock(i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/raster-vs-vector-graphics-images/' })}{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}</div>

export const renderThirdColumnWithAddedLink = () =>
    <div>{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>{renderBlock(i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1, { type: 'link', href: 'https://www.scan2cad.com/tips/use-dwg-dxf/' })}{i10n._VIEW_DXFTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}</div>