export const betaKeys = {
    BETA_OHO_AVE1137_GTAG_EMBEDDED_ORIGIN: "BETA_OHO_AVE1137_GTAG_EMBEDDED_ORIGIN",
};

export function isBetaEnabled(key) {
    switch (key) {
        case betaKeys.BETA_OHO_AVE1137_GTAG_EMBEDDED_ORIGIN: return false;

        default:
            return false;
    }
}