import { PureComponent } from 'react';
import { connect } from 'react-redux';
import i10n from '../i10n';
import { AsyncSVG } from './AsyncSVG';

export function getStepsList (keyPart, renderLinks) {
    var steps = [];
    var stepText = null;
    var stepNumber = 1;

    const linkRegEx = /\[([\w\s\d ]+)\]\(([:\/a-z.-]+)\)/gi;

    while (true) {
        stepText = i10n[`_VIEW_${keyPart}_HOW_TO_USE_STEP_${stepNumber}`];

        if (stepText) {
            //Detect and render links
            if (renderLinks && linkRegEx.test(stepText)) {
                stepText = stepText.replace(linkRegEx, '<a href="$2">$1</a>');
                steps.push(<div dangerouslySetInnerHTML={{ __html: stepText }}></div>);
            }
            else{
                steps.push(stepText);
            }
            ++stepNumber;
        } else {
            break;
        }
    }
    return steps;
}

class HowToUseComponent extends PureComponent {
    render() {
        if (!this.props.keyPart || !i10n[`_VIEW_${this.props.keyPart}_HOW_TO_USE_TITLE`]) {
            return null;
        }
        return (
            <div className="row ave-row-small">
                <div className="col-md-12 how-to-use">
                    <div className="row">
                        <div className="col-lg-12 how-to-use-text">
                            <h3 className="title-fancy title-small mb-5">
                                <span className="title-fancy-icon"><AsyncSVG className="link-arrow" src='/images/title-icon.svg' /></span>
                                {i10n[`_VIEW_${this.props.keyPart}_HOW_TO_USE_TITLE`]}
                            </h3>
                            <ol className="ave-how-to">
                                {getStepsList(this.props.keyPart, true).map((step, index) => {
                                    return (<li key={index}>{step}</li>);
                                })}
							</ol>
                        </div>
                    </div>
                </div>
            </div>
		);
    }
}

const mapState = ({ tool }) => {
    return {
        language: tool.lang
    };
}
const HowToUse = connect(mapState)(HowToUseComponent);
export default HowToUse;