import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';


export const renderFirstColumn = () =>
    <div>{i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
    {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'http://www.dssresources.com/history/mattessichspreadsheet.htm' })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
    {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Classes_of_computers' })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}
    {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1, { type: 'AvePDFLink', href: tools.pptxToPdf.url })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}<br/>
    {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Microsoft_Excel' })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_8_PART_1}
    {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_9_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Lotus_1-2-3' })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_10_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_2_DESC_11_PART_1}</div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}
    {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1, { type: 'link', href: 'https://www.informationsecuritybuzz.com/expert-comments/trojan-hiding-in-attached-microsoft-excel-docs/' })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}<br/>
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1, { type: 'AvePDFLink', href: tools.pptxToPdf.url })}
    {i10n._VIEW_EXCELTOPDF_DIDYOUKNOW_COLUMN_3_DESC_7_PART_1}</div>