import {UserHelper} from "../helpers/userHelper";
import { PureComponent } from 'react';
import loadScript from 'load-script';

export class GoogleAds extends PureComponent {

    componentDidMount() {
        try{
            (window.adsbygoogle = window.adsbygoogle || []).push({});            
        } catch {
            //nevermind
        }
    }

    render() {
        if (this.props.display){
            loadScript("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1662221602032020");
            return (
                <div className="row">
                    <div className="col-12">
                            <ins className="adsbygoogle"
                                style={{ 'display': "block" }}
                                data-ad-client="ca-pub-1662221602032020"
                                data-ad-slot="5557272998"
                                data-ad-format="horizontal"
                                data-full-width-responsive="true">
                                
                            </ins>
                    </div>
                </div>
            );
        }

        return <></>

    }
}
