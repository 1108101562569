import { StrictMode } from 'react';
import App from './App'
import i10n from './common/i10n';
import { initGALocationListener, sendEmbededWidgetOrigin } from './common/trackers';
import { hydrate, render } from 'react-dom';
import { config } from './config';
import { history } from './store';
import sitemap from './common/sitemap';
import { setAvePdfCookieLang, setCookieConsent } from './common/helpers/cookieHelper';
import loadScript from 'load-script';
import { log } from './common/log';
import { ConfigProvider } from 'antd';
import { UserHelper } from './common/helpers/userHelper';

const sendHeightChangedMessage = (elementId) => {
    var widgetContent = document.getElementById("widget-content");

    if (widgetContent) {
        var message = JSON.stringify({
            type: 'heightChanged',
            elementId: elementId,
            payload: widgetContent.clientHeight + 'px'
        });

        window.parent.postMessage(message, '*');
    }
}

const notifyParentAboutChanges = (elementId) => {
    // Select the node that will be observed for mutations
    var targetNode = document.getElementsByClassName("main-wrapper")[0];

    // Options for the observer (which mutations to observe)
    var config = { attributes: false, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    var callback = function (mutationsList, observer) {
        setTimeout(function () {
            sendHeightChangedMessage(elementId);
        }, 0);
    };

    // Create an observer instance linked to the callback function
    var observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}

var queryString = new URLSearchParams(window.location.search);
var hasgclid = queryString.has("gclid");
let isEmbedded = queryString.get("embedded") === "true";
if (isEmbedded) {
    window.embedded = true;
    window._EMBEDDED_ELEMENT_ID = queryString.get("embeddedElementId");
}

i10n.init().then(() => {
    var appInstance = (
        <StrictMode>
            <ConfigProvider direction={window.AvePDFAppState.IsRtl ? "rtl" : "ltr"}>
                <App /> 
            </ConfigProvider>
        </StrictMode>
    );
    const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
        hydrate(appInstance, rootElement);
    } else {
        render(appInstance, rootElement);
    }
    launch();
}).catch(() => {
    if (process.env.NODE_ENV === 'production')
        window.location = `/${i10n.getBestLang()}/${sitemap.VIEW_NOT_FOUND_ROUTE}`
});

function launch() {
    if (!config.isSnapshootingCrawlers && !config.isSnapshootingUsers) {
        if (window.embedded && window._EMBEDDED_ELEMENT_ID) {
            sendHeightChangedMessage(window._EMBEDDED_ELEMENT_ID);
            notifyParentAboutChanges(window._EMBEDDED_ELEMENT_ID);

            //this is needed because of 0 height when iframe is inside some plugin, e.g. jQuery tabs
            setInterval(() => {
                sendHeightChangedMessage(window._EMBEDDED_ELEMENT_ID);
            }, 300);
        }
        if (config.ssrMode !== 1) {
            loadScript("https://www.googletagmanager.com/gtag/js?id=UA-112327012-2", () => {
                initGALocationListener(history);
                if (isEmbedded) {
                    window.parent.postMessage(JSON.stringify({
                        type: 'getOrigin',
                        elementId: window._EMBEDDED_ELEMENT_ID,
                        payload: {}
                    }), '*');
                }
            });
        }
        if (!window.embedded) {
            UserHelper.InitUsage();
            //Disable Zendesk for now
            //setZe();
        }
    }
}

if (config.forceSocialShareOnGoogleAds && hasgclid) {
    config.currentUserHasToSocialShare = true;
}

function setZe() {
    var zeScript = document.getElementById('ze-snippet');
    window.zESettings = {
        webWidget: {
            contactForm: {
                tags: ['avepdf']
            },

            offset: {
                horizontal: '0px', vertical: '0px',
                mobile: {
                    horizontal: '0px',
                    vertical: '0px'
                }
            },

            color: {
                theme: '#041632',
                launcher: '#041632',
                launcherText: '#FFFFFF',
                button: '#041632',
                resultLists: '#041632',
                header: '#041632',
                articleLinks: '#041632',
            }
        }
    };
    zeScript.addEventListener("load", () => {
        window.zE('webWidget', 'setLocale', i10n.currentLang);
    });
    if (window.navigator.userAgent.indexOf('Lighthouse' === -1)) {

        zeScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=a48e089b-4b1f-47f8-bccc-910e97d51635';
    }
}

window.addEventListener('message', (e) => {
    if (e.data.type) {
        switch (e.data.type) {
            case "change-lang":
                if (e.data.payload !== false) {
                    let lang = "";
                    if (e.data.payload === true) {
                        lang = i10n.getBestLang();
                    } else {
                        lang = e.data.payload;
                    }
                    setAvePdfCookieLang(lang);
                    var path = document.location.pathname.slice(1).split("/");
                    if (lang == "en") {
                        path = path.slice(1);
                    } else if (path.length == 1 && path[0].length > 2) {
                        path.unshift(lang);
                    } else {
                        path[0] = lang;
                    }
                    window.location = "/" + path.join("/");
                    break;
                } else {
                    setAvePdfCookieLang(i10n.currentLang);
                    document.getElementById("notif-language-change").style.display = "none";
                }
                break;
            case "cookie-consent":
                setCookieConsent();
                document.getElementById("notif-cookie-consent").style.display = "none";
                break;
            case 'getOriginReponse':
                sendEmbededWidgetOrigin(e.data.payload);
                break;
        }
    }
}, false);

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then((v) => {
            log(["🎉 ServiceWorker::Installed", v]);
        }).catch((reason) => {
            log(["🆘 ServiceWorker::IssueOnInstall", reason]);
        });
    });
} else {
    log("🚫 ServiceWorker::NotSupported");
}
