export const config = {
    forceSocialShareOnGoogleAds: false,
    forceSocialShareWaitBeforeDownload: 45,
    dismissMessageAfterClick: 10,
    currentUserHasToSocialShare: false,
    isSnapshootingCrawlers: window.navigator.userAgent === window.AvePDFAppState.SSR_SHAPSHOT_CRAWLERS_UA,
    isSnapshootingUsers: window.navigator.userAgent === window.AvePDFAppState.SSR_SHAPSHOT_USERS_UA, 
    ssrMode: window.AvePDFAppState.SSRMode,
    allowedLength: window.AvePDFAppState.MaxAllowedContentLength,
    maxUploadFileCount: window.AvePDFAppState.MaxUploadFileCount
}

export default config;