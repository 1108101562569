import React, { useEffect, useState } from 'react';
import localStorageKeys from '../../../constants/localStorageKeys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-regular-svg-icons';
import { betaKeys, isBetaEnabled } from '../../beta';

export default class ThemeSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDark: false
        };
    }


    mutationObserver = null;

    onMediaQueryChange = e => this.setTheme(e.matches);

    componentDidMount() {
        this.setTheme(document.documentElement.getAttribute("data-theme") === "dark");
        if (window.matchMedia) {
            const themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            themeMediaQuery.addEventListener('change', this.onMediaQueryChange);
        }

        this.mutationObserver = new MutationObserver(this.mutationCallback);
        if (this.mutationObserver) {
            this.mutationObserver.observe(document.documentElement, { attributes: true });
        }
    }

    componentWillUnmount() {
        if (window.matchMedia) {
            const themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            themeMediaQuery.removeEventListener('change', this.onMediaQueryChange);
        }

        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    }

    mutationCallback = (mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.type === "attributes" || mutation.attributeName === "data-theme") {
                this.setState({ isDark: mutation.target.getAttribute("data-theme") === "dark" });
            }
        }
    }

    transitionThemeStart = () => {
        document.documentElement.classList.add("theme-switching");
    }

    transitionThemeEnd = ev => {
        if (ev.propertyName == "transform")
            document.documentElement.classList.remove("theme-switching");
    }

    /**
     * Set the theme according to a boolean value that represent dark version if is true
     * Executes also the `onThemeChange` callback function
     *
     * @returns {void}
     */
    setTheme = (isDarkTheme) => {
        const theme = isDarkTheme === true ? "dark" : "light";
        document.documentElement.setAttribute("data-theme", theme);
        window.localStorage.setItem(localStorageKeys.THEME, theme);
        this.setState({ isDark: isDarkTheme })

        if (this.props.onThemeChange) this.props.onThemeChange(theme);
    }

    render = () => {
        return (
            <div className="theme-switch">
                <div className="btn">
                    <div className={`btn_background ${this.state.isDark ? 'color' : ''}`} onClick={() => { this.setTheme(!this.state.isDark); this.transitionThemeStart(); }}>
                        <div className={`btn_face ${this.state.isDark ? 'move' : ''}`} onTransitionEnd={(ev) => { this.transitionThemeEnd(ev); }}>
                            <div className={`btn_animation ${this.state.isDark ? 'move' : 'move-reverse'}`}></div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}