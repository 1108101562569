import { getLocalizedLink } from '../../../common/components/tool';
import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import sitemap from '../../../common/sitemap';
import tools from '../../../constants/tools';

export const renderFirstColumnWithAddedLink = () =>
    <>
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_1_DESC_1_PART1}
        {renderBlock(i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_1_DESC_1_PART_2, { type: 'link', href: 'https://en.wikipedia.org/wiki/Grayscale' })}
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_1_DESC_2} <br/>
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_1_DESC_3} <br/>
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_1_DESC_4}
    </>


export const renderSecondColumnWithAddedLink = () =>
    <>
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_1} <br />
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_2} <br />
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_3} <br />
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_4} 
        {renderBlock(i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_5, { type: 'AvePDFLink', href: tools.hyperCompress.url })} 
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_6} 
        {renderBlock(i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_2_DESC_7, { type: 'link', href: 'https://www.gdpicture.com/ocr-sdk/hyper-compression/' })} 
    </>

export const renderThirdColumnWithAddedLink = () =>
    <>
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_3_DESC_1} <br />
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_3_DESC_2} <br />
        {renderBlock(i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_3_DESC_3, { type: 'link', href: `https://en.wikipedia.org/wiki/Colorimetry` })} 
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_3_DESC_4} <br />
        {i10n._VIEW_CONVERTTOGRAYSCALE_DIDYOUKNOW_COLUMN_3_DESC_5} 
    </>