export const steps = {
    upload: 1,
    action: 2,
    progress: 3,
    success: 4,
    loadingTool: 5,
    error: 6
}

export const redirectionSteps = {
    noRedirection: 0,
    beforeRedirection: 1,
    inRedirection: 2,
    afterRedirection: 3
}