import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumn = () =>
    <>
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_1_DESC_1} 
        {renderBlock(i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_1_DESC_2, { type: 'AvePDFLink', href: tools.convertA.url })}
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_1_DESC_3}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_1_DESC_4}
    </>

export const renderSecondColumn = () =>
    <>
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_2_DESC_1}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_2_DESC_2}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_2_DESC_3}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_2_DESC_4}
    </>

    export const renderThirdColumn = () =>
    <>
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_DESC_1}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_DESC_2}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_DESC_3}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_DESC_4}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_DESC_5}
        <br />
        {i10n._VIEW_PDFA_VALIDATOR_DIDYOUKNOW_COLUMN_3_DESC_6}
    </>