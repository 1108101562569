

import { Button } from 'antd';
import { CommonLayout } from '../../common/components/layouts/layout';
import { connect } from 'react-redux';
import { getLocalizedLink } from '../../common/components/tool';
import i10n from '../../common/i10n';
import internalServerError from '../../assets/images/avepdf-500-error.png';
import { Link } from 'react-router-dom';
import { Metadata } from '../../common/metadata';

export const InternalServerErrorView = () => {
    return <CommonLayout className="vmiddle" pageId="">
        <Metadata />
        <div className="row" >
            <div className="col-md-12">
                <div className="center-text home-header-container">
                    <div className="row justify-content-md-center align-items-center not-found">
                        <div className="col-md-6 text-dark">
                            <div className="not-found-header">500</div>
                            <div className="not-found-title">
                                {i10n._VIEW_500_NOT_FOUND_TITLE}
                            </div>
                            <div className="not-found-subtitle">
                                {i10n._VIEW_500_NOT_FOUND_SUBTITLE}
                            </div>
                            <div>
                                <Link to={getLocalizedLink('')}>
                                    <Button className="action-button not-found-action mb-4">{i10n._VIEW_500_GO_TO_HOME_PAGE}</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-5 not-found-image">
                            <img alt="Internal server error image" src={internalServerError} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </CommonLayout>;
};

const mapState = ({ tool }) => {
    return {
        language: tool.lang
    };
}

export const InternalServerError = connect(mapState)(InternalServerErrorView);