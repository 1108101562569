export const cookieWarning = {
    shown: false
};

export function cookieWarningShown() {
    cookieWarning.shown = true;
}

export const CookiesKeys = {
    CC_Status: "cookieconsent_status",
    AvePdf_Lang: "lang",
    PassportPdf_Lang: "AvePDF_Locale",
    DiscardBanner: "DiscardBanner"
}