import { PureComponent } from 'react';
import { Metadata } from '../../common/metadata';
import i10n from '../../common/i10n';
import { connect } from 'react-redux';
import { Tool, ToolStatus } from '../../common/components/tool';
import { CommonLayout } from '../../common/components/layouts/layout';
import clear from '../../common/components/clear';
import tools from '../../constants/tools';
import { toolCategories } from '../../constants/tools';
import { sendCategorySelectedEvent, sendTextSearchToolNotFoundEvent } from '../../common/trackers';
import { onSearchToolChange } from '../../common/reducers/toolLayoutReducer';
import { Tiles } from '../../common/components/tiles';
import fuzzysort from 'fuzzysort'
import SearchTool from '../../common/components/searchTool';
import {GoogleAds} from "../../common/components/googleAds";

export const pageId = "7A364B24-7CB8-4883-908B-458A768CCBE6";

export class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchSentence: null,
            category: toolCategories.popular
        }
    }


    onCategorySelected = (category) => {
        this.props.clearSearchToolRequest();
        if (category === this.state.category) {
            return;
        }
        if (category !== this.state.category) {
            switch (category) {
                case toolCategories.none:
                    sendCategorySelectedEvent("all");
                    break;
                case toolCategories.convert:
                    sendCategorySelectedEvent("convert");
                    break;
                case toolCategories.organize:
                    sendCategorySelectedEvent("organize");
                    break;
                case toolCategories.edit:
                    sendCategorySelectedEvent("edit");
                    break;
                case toolCategories.protect:
                    sendCategorySelectedEvent("protect");
                    break;
                case toolCategories.optimize:
                    sendCategorySelectedEvent("optimize");
                    break;
            }
        }

        this.setState({
            category: category
        });
    }

    isToolVisible = tool => {
        if (tool.status === ToolStatus.Hidden) {
            return false;
        }

        if (this.props.searchToolRequest === null) {
            return tool.category.includes(this.state.category);
        }

        return true;
    }

    render() {
        var displayedTools = [];
        if (this.props.searchToolRequest !== null && this.props.searchToolRequest !== "") {
            const toolWLabel = Object.values(tools).map(t => { return { ...t, sortKey: i10n.getToolLabels(t).homeTitle } });
            const searchResults = fuzzysort.go(this.props.searchToolRequest, toolWLabel, { key: "sortKey" });

            displayedTools = searchResults.map((result, i) =>
                <div key={result.obj.id} className="col-xl-4 col-lg-6 col-12">
                    <Tool tool={result.obj} />
                </div>
            );
        } else {
            for (var key in tools) {
                if (this.isToolVisible(tools[key])) {
                    displayedTools.push(
                        <div key={tools[key].id} className="col-xl-4 col-lg-6 col-12">
                            <Tool tool={tools[key]} />
                        </div>
                    );
                }
            }
        }

        if (!displayedTools.length) {
            sendTextSearchToolNotFoundEvent(this.props.searchToolRequest);
        }

        return (
            <CommonLayout pageId={pageId} displayAdsGdp={true}>
                <Metadata />
                <div className="home">
                    <div>
                        <header className="row" >
                            <div className="col-md-12">
                                <div className="center-text home-header-container">
                                    <div><h1 className="home-header">{i10n._VIEW_HOME_SUBTITLE}</h1></div>
                                </div>
                            </div>
                        </header>
                        <nav className="row align-items-center pb-5">
                            <div className="col-lg-auto mt-xl-0 mt-lg-2">

                                <div className="ant-btn-group flex-lg-nowrap flex-wrap btn-filters" role="group" aria-label="First group">
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.popular ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.popular) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_POPULAR}</button>
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.convert ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.convert) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_CONVERT}</button>
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.organize ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.organize) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_ORGANIZE}</button>
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.edit ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.edit) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_EDIT}</button>
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.protect ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.protect) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_PROTECT}</button>
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.optimize ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.optimize) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_OPTIMIZE}</button>
                                    <button type="button" className={"ant-btn  " + (this.props.searchToolRequest === null && this.state.category == toolCategories.other ? 'custom-active-on' : 'custom-active-off')} onClick={() => { this.onCategorySelected(toolCategories.other) }}>{i10n._VIEW_HOME_TOOL_CATEGORY_OTHER}</button>
                                </div>

                                <SearchTool passiveMode={false} />

                            </div>
                        </nav>
                        <div className="row small-icons">
                            {
                                displayedTools.length === 0 ?

                                    <h1 className="homepage-no-result-found">
                                        {i10n._VIEW_HOME_TOOL_SEARCH_NO_RESULT}
                                    </h1>
                                    :
                                    <>
                                        {displayedTools}
                                    </>
                            }
                        </div>
                    </div>
                </div >
                <Tiles />
            </CommonLayout >);
    }
};

const mapDispatch = (dispatch) => {
    return {
        clearSearchToolRequest() {
            dispatch(onSearchToolChange(null));
        }
    }
}

const mapState = ({ tool }) => {
    return {
        language: tool.lang,
        searchToolRequest: tool.searchToolRequest
    };
}
const HomeContainer = clear(connect(mapState, mapDispatch)(Home));
export default HomeContainer;


