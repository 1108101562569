class Utilites {
    static generateGUID() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }

        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }
};

export default Utilites;

export const Timer = (duration, updateInterval, onProgress, onEnd) => {

    if (isNaN(duration) || isNaN(updateInterval)) {
        return;
    }

    let dateStart = new Date();

    let timer = setInterval(work, updateInterval);

    function work() {
        const passedTime = parseInt(Date.now() - dateStart) / 1000;
        const rate = (passedTime / duration) * 100;
        const remainedTime = {
            'minutes': parseInt((duration - passedTime) / 60),
            'seconds': (duration - passedTime) % 60,
            ms: passedTime
        };

        if (onProgress && passedTime < duration) {
            onProgress({ 'rate': rate, 'remainedTime': remainedTime });
        }
        else if (onEnd && passedTime >= duration) {
            clearInterval(timer);
            onEnd();
        }
    }

    return timer;
}

export const CopyToClipboard = (text) => {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(text);
    }
    else {
        return new Promise((res, rej) => {
            try {
                var tmp = document.createElement('input');
                document.body.appendChild(tmp)
                tmp.value = this.state.embedScript;
                tmp.select();
                document.execCommand('copy');
                tmp.remove();
                res();
            }
            catch{
                rej();
            }
        })
    }
}

export const GetBrowser = () => {
     // Opera 8.0+
     if ((!!window["opr"] && !!["opr"]["addons"]) || !!window["opera"] || navigator.userAgent.indexOf(' OPR/') >= 0) {
        return 'opera';
    }

    // Firefox 1.0+
    if (typeof window["InstallTrigger"] !== 'undefined') {
        return 'firefox';
    }

    // Safari 3.0+ "[object HTMLElementConstructor]" or iPhone [navigator.standalone]
    if (typeof navigator.standalone === 'boolean' || /constructor/i.test(window["HTMLElement"]) || (function(p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window["safari"] !== 'undefined' && window["safari"].pushNotification))) {
        return 'safari';
    }

    // Internet Explorer 6-11
    if (/*@cc_on!@*/false || !!document["documentMode"]) {
        return 'ie';
    }

    // Edge 20+
    if (!(/*@cc_on!@*/false || !!document["documentMode"]) && !!window["StyleMedia"]) {
        return 'edge';
    }

    // Chrome 1+
    if (!!window["chrome"] && !!window["chrome"].webstore) {
        return 'chrome';
    }

    // Blink engine detection
    if (((!!window["chrome"] && !!window["chrome"].webstore) || ((!!window["opr"] && !!["opr"]["addons"]) || !!window["opera"] || navigator.userAgent.indexOf(' OPR/') >= 0)) && !!window["CSS"]) {
        return 'blink';
    }
}

export const isWindows = () => {
    if (navigator.userAgentData) {
        return navigator.userAgentData.platform === "Windows"
    }
    else if (navigator.platform) {
        return navigator.platform.toLowerCase().startsWith("win");
    }
    else if (navigator.appVersion) {
        return navigator.appVersion.toLowerCase().indexOf("win") > -1;
    }
}

export const timestampToHour = (timespan) => {
    const timePart = timespan.includes(".") ? timespan.split(".")[1] : timespan;
    const hours = parseInt(timePart.split(":")[0]);
    if (hours == 0) {
        return null;
    }
    return `${hours == 1 ? '' : hours} ${hours == 1 ? "hour" : "hours"}`;
}

export const formatDuration = (momentDuration) => {
    return `${momentDuration.hours() <= 9 ? "0" : ""}${momentDuration.hours()}:${momentDuration.minutes() <= 9 ? '0' : ''}${momentDuration.minutes()}:${momentDuration.seconds() <= 9 ? '0': ''}${momentDuration.seconds()}`;
}

export const sleep = time => new Promise(res => setTimeout(res, time, "done sleeping"));