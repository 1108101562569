import { CookiesKeys } from '../../constants/cookieConstant';


const getCookie = (cookieName) => {
    if (cookieName)
        return document.cookie.split(';').map(cookie => cookie.trim().split("=")).find(cookie => cookie[0] === cookieName)?.[1] ?? null;
    else return null;
}

const getAvePdfCookieLang = () => {
    return getCookie(CookiesKeys.AvePdf_Lang);
}

const getPassportPdfCookieLang = () => {
    return getCookie(CookiesKeys.PassportPdf_Lang);
}

const hasCookieLang = () => {
    return getAvePdfCookieLang() !== null || getPassportPdfCookieLang() !== null;
}

const setAvePdfCookieLang = (lang) => {
    document.cookie = `${CookiesKeys.AvePdf_Lang}=${lang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
}

const discardBanner = (bannerId) => {
    document.cookie = `${CookiesKeys.DiscardBanner}_${bannerId}=dismiss; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
}

const hasDiscardedBanner = (bannerId) => {
    console.log(getCookie(`${CookiesKeys.DiscardBanner}_${bannerId}`));
    return getCookie(`${CookiesKeys.DiscardBanner}_${bannerId}`) !== null;
}

const hasCookieConsent = () => {
    var cookie = getCookie(CookiesKeys.CC_Status);
    return cookie !== null && cookie === "dismiss";
}

const setCookieConsent = () => {
    document.cookie = `${CookiesKeys.CC_Status}=dismiss; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
}

export {
    getCookie,
    getPassportPdfCookieLang,
    getAvePdfCookieLang,
    setAvePdfCookieLang,
    hasCookieConsent,
    setCookieConsent,
    hasCookieLang,
    hasDiscardedBanner,
    discardBanner
};