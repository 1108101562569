import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import i10n from '../../common/i10n';
import { AsyncSVG } from './AsyncSVG';

export const ToolStatus = {
    None: 0,
    Highlight: 1,
    New: 2,
    ComingSoon: 3,
    Hidden: 4,
    Beta: 5
}

export const goTop = () => {
    if (window.embedded) {
        return;
    }

    const header = document.querySelector('#root');
    setTimeout(() => {
        header.scrollIntoView({
            behavior: 'smooth'
        }, 100)
    }, 50);
}
export const getLocalizedLink = (route, lang) => {
    var targetLang = lang ? lang : i10n.currentLang;
    var link = route ? `/${targetLang}/${route}` : `/${targetLang}`;

    if (link.startsWith("/en")) {
        link = link.substring(3);
    }
    return link;
}

export class Tool extends PureComponent {
    render() {
        const toolLabels = i10n.getToolLabels(this.props.tool);
        var className = `tool-item-wrapper h-100 ${this.props.tool.fillClassName}`;
        const cardContent = <>
            <div className={`tool-item-block h-100 ${this.props.tool.status === ToolStatus.ComingSoon ? 'coming-soon-icon' : ''} ${this.props.tool.status === ToolStatus.Beta ? 'beta-tool-icon' : ''}`}>
                <div className="tool-item-block-icon">
                    <AsyncSVG src={this.props.tool.image} />
                </div>
                <div className="tool-item-block-description">
                    <h2 className="tool-item-block-title">
                        <div className="row">
                            <div className="col-10">
                                {toolLabels.homeTitle}
                            </div>
                            <div className="col-2">
                            {
                                this.props.tool.url().startsWith('http') ?
                                    <AsyncSVG src="/images/external-website-arrow-md.svg" />
                                    : 
                                    <>
                                    </>
                            }
                        </div>
                        </div>
                    </h2>
                    <p className="tool-item-block-description">{toolLabels.homeSubTitle}</p>
                </div>
                {
                    this.props.tool.status === ToolStatus.ComingSoon &&
                    <span className="coming-soon">
                            {i10n._VIEW_HOME_TOOL_GRID_COMING_SOON}
                        </span>
                }
                {
                    this.props.tool.status === ToolStatus.Beta &&
                    <div className="tool-label bg-danger">
                        <p>{i10n._VIEW_LAYOUT_BETA_BADGE}</p>
                    </div>
                }
                {
                    this.props.tool.status === ToolStatus.New &&
                    <div className="tool-label bg-danger">
                        <p>{i10n._VIEW_HOME_TOOL_GRID_NEW}</p>
                    </div>
                }
            </div>
        </>

        return (<div className={className} key={this.props.tool.id}>
            {
                this.props.tool.url().startsWith('http') ?
                 <a href={this.props.tool.url()} target="_blank" rel="noopener noreferrer">
                     {cardContent}
                 </a>    
                :
                <Link to={this.props.tool.url()}>
                    {cardContent}
                </Link>
            }
        </div>);
    }
}